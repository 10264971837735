import { Link } from "react-router-dom";
import styled from "styled-components";
export const StyledHeader = styled.header`
  position: relative;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
  background-color: var(--grey);
  height: 30vh;
  max-height: 500px;
  min-height: 250px;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 20vh;
    background-color: var(--grey);
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), var(--black));
    position: absolute;
    top: 100%;
    z-index: -1;
  }

  .header__inner {
    justify-content: center;
    align-items: center;

    display: flex;
    flex-direction: column;
  }

  .header__overline {
    text-transform: uppercase;
    font-size: var(--fz-xxs);
    font-weight: 700;
    margin-bottom: var(--spacing-xs);
  }

  h1.header__name {
    font-size: 50px;
    font-weight: 900;
    line-height: 1;
    margin: 0 0 var(--spacing-xs) 0;
  }
`;

export const StyledGrid = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-gap: var(--spacing-sm);

  .grid__item__img {
    position: relative;
    padding-top: 100%;
    margin: 0 auto var(--spacing-lg);
  }

  .grid__item__name {
    margin: 0 0 var(--spacing-xxs);
    font-size: var(--fz-md);
    letter-spacing: normal;
  }

  .grid__item__label {
    font-size: var(--fz-sm);
    color: var(--light-grey);
  }
`;
export const CardLink = styled(Link)`
  text-decoration: none;
  width: auto;
  user-select: auto;
  text-align: left;
  color: #675252;
  margin: 30px;
  border: 4px solid gray;
  padding: 10px;
  border-radius: 7px;
  background: #a9a9a93d;
  cursor: pointer;
`;
