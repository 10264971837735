
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import Box from "@mui/material/Box";
import Link from '@mui/material/Link';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { login } from "../../redux/slices/userSlice";
import { AppDispatch } from "../../redux/store/store";
import { IUserLogin } from "../../interface/user";
import Cookies from "js-cookie";
import { Grid } from "@mui/material";
const theme = createTheme();

export default function SignIn() {
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState<IUserLogin>({
    email: "",
    password: "",
  });
  const handleSubmit = (event: any) => {
    event.preventDefault();
    dispatch(login(data))
      .then((res) => {
        Cookies.set("accessToken", res.payload.token);
        if (res.payload.token) {
          window.location.href="/"
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              onChange={(e) => setData({ ...data, email: e.target.value })}
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setData({ ...data, password: e.target.value })}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/signup" variant="body2">
                  Need an account? Sign Up
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
