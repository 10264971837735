import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {  useNavigate } from "react-router-dom";
import { IArticleData } from "../../interface/article";
import { createArticle } from "../../redux/slices/articleSlice";
import { AppDispatch } from "../../redux/store/store";
import {
  FormGroup,
  Input1,
  Input2,
  Input3,
  Label,
  Button,
} from "./Article.Styles";

const CreateArticle = () => {
    const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [addArticle, setAddArticle] = useState<IArticleData>();
  const submitArticle = () => {
    const data={
        article: addArticle as IArticleData
    }
    
  
    dispatch(createArticle(data)).then((res) => {
        navigate("/");
    });
  };
  return (
    <div>
      <FormGroup>
        <Label htmlFor="label">Title</Label>
        <Input1
          id="label"
          onChange={(e) =>
            setAddArticle({
              ...addArticle,

              title: e.target.value,
            } as IArticleData)
          }
        />
      </FormGroup>
      <FormGroup>
        <Label>Description</Label>
        <Input2
          onChange={(e) =>
            setAddArticle({
              ...addArticle,

              description: e.target.value,
            } as IArticleData)
          }
        />
      </FormGroup>
      <FormGroup>
        <Label>Body</Label>
        <Input3
          onChange={(e) =>
            setAddArticle({
              ...addArticle,

              body: e.target.value,
            } as IArticleData)
          }
        />
      </FormGroup>

      <Button onClick={submitArticle}>Submit Article</Button>
    </div>
  );
};

export default CreateArticle;
