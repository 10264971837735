import "./App.css";
import Navbar from "./components/menu/Navbar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SignIn from "./components/user/Login";
import SignUp from "./components/user/SignUp";
import Article from "./components/article/Article";
import ProtectedRoute, { ProtectedRouteProps } from "./Protected";
import Cookies from "js-cookie";
import Main from "./components/main/Main";
import CreateArticle from "./components/article/CreateArticle";
import Profile from "./components/Profile/Profile";
const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
  isAuthenticated: Cookies.get("accessToken") ? true : false,
  authenticationPath: "/login",
};

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/login" element={<SignIn />}></Route>
          <Route path="/signup" element={<SignUp />}></Route>
          <Route path="/" element={<Main />}></Route>
       
        
          <Route
            path="/article-form"
         
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<CreateArticle />}
              />
            }
          />
                 <Route
            path="/profile"
         
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<Profile />}
              />
            }
          />
          <Route
            path="/:slug"
         
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<Article />}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
