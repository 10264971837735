import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IArticleData,
  ISlug,
  IArticleDataUpdate,
} from "../../interface/article";
import { axiosRequestNew } from "../axios/axiosRequest";

export const createArticle = createAsyncThunk(
  "createArticle",
  (data:any) => {
    const res = axiosRequestNew.post(`/articles`, data).then((res) => res.data);
    return res;
  }
);
export const deleteArticle = createAsyncThunk(
  "deleteArticle",
  (data: ISlug) => {
    const res = axiosRequestNew
      .delete(`/articles/${data.slug}`)
      .then((res) => res.data);
    return res;
  }
);
export const getAllArticle = createAsyncThunk("getAllArticle", () => {
  const res = axiosRequestNew.get(`/articles`).then((res) => res.data);
  return res;
});
export const getArticleBySlug = createAsyncThunk(
  "getArticleBySlug",
  (slug: string) => {
    const res = axiosRequestNew
      .get(`/articles/${slug}`)
      .then((res) => res.data);
    return res;
  }
);
export const getFeedArticle = createAsyncThunk("getFeedArticle", () => {
  const res = axiosRequestNew.get(`/articles/feeds`).then((res) => res.data);
  return res;
});
export const updateArticle = createAsyncThunk(
  "updateArticle",
  (data: IArticleDataUpdate) => {
    const res = axiosRequestNew
      .patch(`/articles/${data.slug}`)
      .then((res) => res.data);
    return res;
  }
);
export const articleSlice = createSlice({
  name: "article",
  initialState: {
    articleAdd: {},
    articleDelete: {},
    articleUpdate: {},
    slugArticle: {},
    allArticle: {},
    feedArticle: {},
  },
  reducers: {},
  extraReducers: {
    [createArticle.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<IArticleData>
    ) => {
      state.articleAdd = payload;
    },
    [deleteArticle.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<Boolean>
    ) => {
      state.articleDelete = payload;
    },
    [updateArticle.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<IArticleData>
    ) => {
      state.articleUpdate = payload;
    },
    [getArticleBySlug.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<IArticleData>
    ) => {
      state.slugArticle = payload;
    },
    [getAllArticle.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<[IArticleData]>
    ) => {
      state.allArticle = payload;
    },
    [getFeedArticle.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<[IArticleData]>
    ) => {
      state.feedArticle = payload;
    },
  },
});

export default articleSlice.reducer;
