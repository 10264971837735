import Axios from "axios";
import Cookies from "js-cookie";

export const axiosRequestNewAuth = Axios.create({
  baseURL: `${process.env.REACT_APP_SERVER}/api`,
});
export const axiosRequestNew = Axios.create({
  baseURL: `${process.env.REACT_APP_SERVER}/api`,
  headers:{
    "Content-Type": "application/json",
    Authorization: `Bearer ${Cookies.get("accessToken")}`
  }
});