import { IArticleData } from "../../interface/article";
import { CardLink, StyledGrid } from "./Profile.Styles";

const ArticleGrid = ({ articles }: { articles: IArticleData[] }) => (
  <>

    {articles && articles.length ? (
      <StyledGrid>
   
        {articles.map((item) => {
          return (
            <CardLink to={`/${item.slug}`}>
              <div className="grid__item__inner">
                {" "}
                <h3 className="grid__item__name overflow-ellipsis">
                  {item.title}
                </h3>
                <p className="grid__item__label">{item.description}</p>
              </div>
            </CardLink>
          );
        })}
      </StyledGrid>
    ) : (
      <p className="empty-notice">No Articles available</p>
    )}
  </>
);

export default ArticleGrid;
