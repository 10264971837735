import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import {  getAllComment, postComment } from "../../redux/slices/commentSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store/store";
import { ISlug } from "../../interface/article";
import { IcommentData } from "../../interface/comments";
import { Avatar, Grid, Stack, TextField, Typography } from "@mui/material";
// import { ThemeProvider } from "styled-components";
type Anchor = "top" | "left" | "bottom" | "right";

export default function TemporaryDrawer(props: ISlug) {
  const dispatch = useDispatch<AppDispatch>();
  const [state, setState] = React.useState({
    right: false,
  });
  const [comments, setComments] = React.useState<IcommentData[]>();
  const [commentBody, setCommentBody] = React.useState<string>();
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };
  // const deleteCommentFunction = (id:string) => {

  //   dispatch(deleteComment(id))
  // };
  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {comments?.map((comment: IcommentData) => {
        return (
          <List>
            <Stack direction="row" spacing={2}>
              <Avatar
                sx={{ width: 24, height: 24, margin: 0.5 }}
                alt="Remy Sharp"
              >
                {comment.userComment.userName.charAt(0)}
              </Avatar>
              <Typography
                component="h6"
                variant="h6"
                gutterBottom
                sx={{ width: 24, height: 24, margin: 1 }}
              >
                {comment.userComment.userName}
              </Typography>
            </Stack>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
     
                <Typography
                  component="p"
                  variant="overline"
                  sx={{ margin: 0.3 }}
                >
                  {comment.body}
                </Typography>
              </Grid>
              <Grid item>
          
                {/* <DeleteIcon onClick={()=>deleteCommentFunction(comment.id)}/> */}
              </Grid>
            </Grid>
          </List>
        );
      })}
    </Box>
  );
  React.useEffect(() => {
    if (props.slug) {
      dispatch(getAllComment(props.slug)).then((res) => {
        setComments(res.payload);
      });
    }
  }, []);

  const submitComment = () => {
    let data = {
      comment: {
        body: commentBody,
      },

      slug: props.slug,
    };
    dispatch(postComment(data)).then((res) => {
      setComments((com: any) => [
        ...com,
        {
          body: res.payload.body,
          id: res.payload.id,
          userComment: res.payload.userComment,
        },
      ]);
    });
  };
  return (
    <div>
      {(["right"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>Comments</Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <Typography component="h5" variant="h5" sx={{ margin: 0.5 }}>
              {" "}
              Responses ({comments?.length})
            </Typography>
            <TextField
              id="outlined-basic"
              label="Add your thoughts"
              sx={{ margin: 1 }}
              variant="outlined"
              onChange={(e) => setCommentBody(e.target.value)}
            />
            <Button variant="text" onClick={submitComment}>
              Submit
            </Button>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
