import { Avatar } from "@mui/material";
import Cookies from "js-cookie";
import { IconContext } from "react-icons";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  Menu,
  MenuItem,
  MenuLink,
} from "./Navbar.styles";
const Navbar = () => {
  const token = Cookies.get("accessToken");

  const logout = () => {
    Cookies.remove("accessToken");
  };

  return (
    <div>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav>
          <NavbarContainer>
            <NavLogo to="/">Medium Clone</NavLogo>

            <Menu>
              {token ? (
                <>
                  <MenuItem>
                    <MenuLink to={"/article-form"}>Create Article</MenuLink>{" "}
                  </MenuItem>
                  <MenuItem>
                    <MenuLink onClick={logout} to="/login">
                      Logout
                    </MenuLink>
                  </MenuItem>

                  <MenuItem>
                    <MenuLink to="/profile">
                      <Avatar
                        sx={{ width: 40, height: 40, margin: 0.5 }}
                        alt="Remy Sharp"
                      >
                        
                      </Avatar>
                    </MenuLink>
                  </MenuItem>
                </>
              ) : (
                <MenuItem>
                  {" "}
                  <MenuLink to="/login">Login</MenuLink>{" "}
                </MenuItem>
              )}
            </Menu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </div>
  );
};

export default Navbar;
