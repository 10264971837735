import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IArticleData, IArticleDataAuthor } from "../../interface/article";
import { getArticleBySlug } from "../../redux/slices/articleSlice";
import { AppDispatch } from "../../redux/store/store";
import { ArticleDiv, Body, Description, Title } from "./Article.Styles";
import { useParams } from "react-router-dom";
import TemporaryDrawer from "../comments/Drawer";
const Article = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [articles, setArticles] = useState<IArticleDataAuthor>();
  const { slug } = useParams();

  useEffect(() => {
    if (slug) {
      dispatch(getArticleBySlug(slug)).then((res) => {
        setArticles(res.payload);
      });
    }
  }, []);
  return (
    <ArticleDiv>
      <Title>{articles?.title}</Title>
      <Description>{articles?.description}</Description>
      <Body>{articles?.body}</Body>

      <TemporaryDrawer slug={slug} />
    </ArticleDiv>
  );
};

export default Article;
