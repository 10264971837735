import styled from "styled-components";
export const ArticleDiv = styled.div`
  text-align: initial;
  text-decoration: none;
  margin: 10px;
`;
export const Title = styled.h2`
  color: #000;
  font-weight: Bold;
`;

export const Description = styled.h3`
  color: #000;
  font-weight: 400;
`;
export const Body = styled.p`
  color: #000;
  font-weight: 300;
`;

export const FormGroup = styled.div`
display: block;
width: 600px;
margin: 50px auto;
}
`;

export const Label = styled.label`
  margin-bottom: 0.5em;
  text-align: left;
  display: block;
`;

export const Input1 = styled.input`
  padding: 0.5em;
  border-radius: 3px;
  width: 100%;
  height: 30px;
  margin-bottom: 0.5em;
`;
export const Input2 = styled.input`
  padding: 0.5em;
  border-radius: 3px;
  width: 100%;
  height: 50px;
  margin-bottom: 0.5em;
`;

export const Input3 = styled.input`
  padding: 0.5em;
  border-radius: 3px;
  width: 100%;
  height: 100px;
  margin-bottom: 0.5em;
`;
export const Button = styled.button`
  margin-bottom: 0.5em;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  display: block;
  border: none;
  margin: 50px auto;
  padding: 10px;
  cursor: pointer;
`;
