import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { IUser, IUserProfile } from "../../interface/user";
import { getUser } from "../../redux/slices/userSlice";
import { AppDispatch } from "../../redux/store/store";
import ArticleGrid from "./ArticleGrid";
import { StyledHeader } from "./Profile.Styles";
import { Avatar } from "@mui/material";
import { getFeedArticle } from "../../redux/slices/articleSlice";
import { IArticleData } from "../../interface/article";
const Profile = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [profile, setProfile] = useState<IUserProfile>();
  const [articles, setArticles] = useState<IArticleData[]>([]);
  useEffect(() => {
    dispatch(getUser()).then((res) => {
      setProfile(res.payload.user);
    });
    dispatch(getFeedArticle()).then((res) => {
      setArticles(res.payload);
    });
  }, []);

  return (
    <>
      {profile && (
        <>
          <StyledHeader>
            <div className="header__inner">
              <Avatar
                sx={{ width: 100, height: 100, margin: 0.5 }}
                alt="Remy Sharp"
                className="header__img"
              >
                {profile.userName.charAt(0)}
              </Avatar>

              <div>
                <div className="header__overline">Profile</div>
                <h1 className="header__name">{profile?.userName}</h1>
                <p className="header__meta">
                  <span>{profile.bio}</span>
                </p>
              </div>
            </div>
          </StyledHeader>

          <ArticleGrid articles={articles} />
        </>
      )}
    </>
  );
};

export default Profile;
