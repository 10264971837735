
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Cardbox=styled(Link)`
border: 2px solid #ccc;
display:block;
margin:4px;
background:#eaeaea;
width:80%;
text-align: initial;
text-decoration:none

`
export const Title = styled.h2`
  color: #000;
  font-weight: 300;
`

export const Description = styled.p`
  color: #000;
  font-weight: 300;
`
export const ActionButton = styled.button`
  margin: 5px 5px;
  padding: 8px 14px;
  background: rgba(155, 155, 155, 0.2);
  color: #000;
  cursor: pointer;
  border: 1px solid #fff;
  outline: 0;
  font-weight: 300;
  :hover {
    opacity: 0.8;
  }
` 