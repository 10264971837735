import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IArticleData } from "../../interface/article";
import { getAllArticle } from "../../redux/slices/articleSlice";
import { AppDispatch } from "../../redux/store/store";
import Card from "../Cards/Card";

const Main = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [articles, setArticles] = useState<[IArticleData] | undefined>();
  useEffect(() => {
    dispatch(getAllArticle()).then((res) => {
      setArticles(res.payload);
    });
  }, []);
  return (
    <div>
      {articles?.map((item: IArticleData) => {
        return (
          <Card
            title={item.title}
            description={item.description}
            body={item.body}
            slug={item.slug}
          />
        );
      })}
    </div>
  );
};

export default Main;
