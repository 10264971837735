import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IcommentBody, IcommentData } from "../../interface/comments";

import { axiosRequestNew } from "../axios/axiosRequest";


export const postComment = createAsyncThunk("postComment", (data:IcommentBody) => {
  const res = axiosRequestNew.post(`/comments/${data.slug}`, data).then((res) => res.data);
  return res;
});
export const getAllComment = createAsyncThunk("getAllComment", (slug:string) => {
  const res = axiosRequestNew.get(`/comments/${slug}`).then((res) => res.data);
  return res;
});
export const deleteComment = createAsyncThunk("deleteComment", (id:string) => {
  const res = axiosRequestNew.get(`/comments/${id}`).then((res) => res.data);
  return res;
});
export const updateComment = createAsyncThunk("updateComment", (id:string) => {
    const res = axiosRequestNew.get(`/${id}`).then((res) => res.data);
    return res;
  });
export const commentSlice = createSlice({
  name: "comment",
  initialState: {
    createComment: {},
    deletedComment: {},
    updatedComment: {},
    getfeedsComment:{}
  },
  reducers: {},
  extraReducers: {
    [postComment.fulfilled.toString()]: (state, { payload }: PayloadAction<IcommentData>) => {
      state.createComment = payload;
    },
    [deleteComment.fulfilled.toString()]: (state, { payload }: PayloadAction<Boolean>) => {
      state.deletedComment = payload;
    },
    [updateComment.fulfilled.toString()]: (state, { payload }: PayloadAction<IcommentData>) => {
      state.updatedComment = payload;
    },
    [getAllComment.fulfilled.toString()]: (state, { payload }: PayloadAction<[IcommentData]>) => {
        state.getfeedsComment = payload;
      },
  },
});


export default commentSlice.reducer;
