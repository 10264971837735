import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser, IUserLogin } from "../../interface/user";
import {  axiosRequestNew, axiosRequestNewAuth } from "../axios/axiosRequest";


export const register = createAsyncThunk("register", (data:IUser) => {
  const res = axiosRequestNewAuth.post(`/users`, data).then((res) => res.data);
  return res;
});

export const login = createAsyncThunk("login", (data:IUserLogin) => {
  const res = axiosRequestNewAuth.post(`/users/login`, data).then((res) => res.data);
  return res;
});

export const getUser = createAsyncThunk("getUser", () => {
  const res = axiosRequestNew.get(`/user`).then((res) => res.data);
  return res;
});
export const userSlice = createSlice({
  name: "user",
  initialState: {
    createUser: {},
    loginData: {},
    userData: {},
  },
  reducers: {},
  extraReducers: {
    [register.fulfilled.toString()]: (state, { payload }: PayloadAction<IUser>) => {
      state.createUser = payload;
    },
    [login.fulfilled.toString()]: (state, { payload }: PayloadAction<IUserLogin>) => {
      state.loginData = payload;
    },
    [getUser.fulfilled.toString()]: (state, { payload }: PayloadAction<IUserLogin>) => {
      state.userData = payload;
    },
  },
});


export default userSlice.reducer;
